<template>
  <v-app>
    <Header :user="user" />

    <v-main :class="!$route.name || ['home', 'trainings', 'individual', 'company', 'aboutUs'].includes($route.name) ? 'pt-0' : ''">
      <error401 v-if="$route.meta.needLogin && !user && !loading" />
      <router-view v-else-if="!loading" class="w-100 overflow-hidden" />
    </v-main>

    <footer class="pa-5 text-center" style="z-index: 1;">
      COPYRIGHT &copy; {{ new Date().getFullYear() }} Lœsus Pedagogia, All rights Reserved
    </footer>
  </v-app>
</template>

<script>
import { getAuth } from '@firebase/auth'

import Header from '@/components/common/Header.vue'
import error401 from '@/views/errors/401.vue'

export default {
  components: { Header, error401 },
  data() {
    return {
      user: null,
      loading: true
    }
  },
  mounted() {
    getAuth().onAuthStateChanged(async user => {
      if (user) {
        this.user = user
      } else {
        this.user = null
      }
      this.loading = false
    })
  }
}
</script>

<style>
  @media print {
    .pagebreak {
      page-break-before: always;
    }
    .hide-on-print{
      display: none;
    }
  }
</style>