<template>
  <v-app-bar elevation=1 class="hide-on-print" style="background-color: rgba(255, 255, 255, .9);">
    <router-link to="/" class="h-100 py-1 px-3" @click="goToHome()">
      <img :src="require('@/assets/images/logos/illuBF.svg')" class="h-100">
    </router-link>
    <v-spacer />

    <template v-if="!isCompact">
      <v-tabs v-model="tab">
        <v-tab class="d-none" />
        <template v-for="item of (user ? adminMenu : menu)">
          <v-tab :href="onHomeRoute ? item.path : ''" @click="item.atClick" v-if="item !== 'divider'" :class="item.color ? `text-${item.color}` : ''">
            <div class="d-flex flex-column align-center py-2">
              <v-icon size=large>{{ item.icon }}</v-icon>
              {{ item.title }}
            </div>
            <v-tooltip v-if="item.tooltip" activator=parent location=bottom>{{ item.tooltip }}</v-tooltip>
          </v-tab>
        </template>
      </v-tabs>
    </template>

    <template v-else>
      <v-spacer />
      <v-btn color=primary>
        <v-icon size=xx-large>mdi-menu</v-icon>
        <v-menu activator=parent>
          <v-list min-width=80vw>
            <template v-if="user">
              <p class="text-center text-truncate px-6">{{ user.email }}</p>
              <CustomDivider class="my-3"/>
            </template>
            <template v-for="item of (user ? adminMenu : mobileMenu)">
              <CustomDivider v-if="item === 'divider'" class="my-3"/>
              <v-list-item v-else :href="onHomeRoute ? item.path : ''" @click="item.atClick">
                <v-list-item-title class="font-weight-100">
                  <v-icon class="mr-5">{{ item.icon }}</v-icon>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-btn>
    </template>

  </v-app-bar>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { getAuth } from '@firebase/auth'

import CustomDivider from '@/components/common/CustomDivider.vue'

export default {
  components: { CustomDivider },
  props: ['user'],
  data(vm) {
    return {
      tab: null,
      menu: [
        { title: 'Notre actu',
          path: '/#actu',
          icon: 'mdi-newspaper',
          atClick: () => vm.goToHome('actu')
        },
        { title: 'Qui sommes-nous',
          path: '/#about-us',
          icon: 'mdi-account-question-outline',
          atClick: () => vm.goToHome('about-us')
        },
        { title: 'Nous contacter',
          path: '/#contact',
          icon: 'mdi-email-fast-outline',
          atClick: () => vm.goToHome('contact')
        }
      ],
      mobileMenu: [
        { title: 'Notre actu',
          path: '/#actu',
          icon: 'mdi-newspaper',
          atClick: () => vm.goToHome('actu')
        },
        { title: 'Qui sommes-nous',
          path: '/#about-us',
          icon: 'mdi-account-question-outline',
          atClick: () => vm.goToHome('about-us')
        },
        'divider',
        { title: 'Trouver ma formation',
          route: '/trainings',
          icon: 'mdi-account-school-outline',
          atClick: () => vm.goToRoute('/trainings')
        },
        { title: 'Je suis un particulier',
          path: '/#individual',
          icon: 'mdi-account-box-outline',
          atClick: () => vm.goToHome('individual')
        },
        { title: 'Je suis une entreprise',
          path: '/#company',
          icon: 'mdi-office-building-outline',
          atClick: () => vm.goToHome('company')
        },
        'divider',
        { title: 'Nous contacter',
          path: '/#contact',
          icon: 'mdi-email-fast-outline',
          atClick: () => vm.goToHome('contact')
        }
      ]
    }
  },
  methods: {
    goToHome(section) {
      const scrollTo = () => {
        if (section)
          document.getElementById(section).scrollIntoView()
        else {
          this.tab = null
          window.scrollTo(0, 0)
        }
      }

      if (this.$route.name === 'home') {
        scrollTo()
      } else {
        this.$router.push('/').then(() => {
          setTimeout(() => {
            scrollTo()
          }, 200)
        })
      }
    },
    goToRoute(path) {
      this.$router.push(path)
    },
    logout() {
      Swal.fire({
        icon: 'question',
        title: 'Se déconnecter ?',
        confirmButtonText: 'Confirmer',
        showCancelButton: true,
        cancelButtonText: 'Annuler', 
      }).then(result => {
        if (result.isConfirmed) {
          getAuth().signOut()
          this.goToHome()
        }
      })
    }
  },
  computed: {
    isCompact() {
      return this.$vuetify.display.xs
    },
    onHomeRoute() {
      return this.$route.name === 'home'
    },
    adminMenu() {
      return [
        { title: 'Formations',
          icon: 'mdi-school-outline',
          atClick: () => this.goToRoute('/admin-trainings')
        },
        { title: 'Actus',
          icon: 'mdi-newspaper',
          atClick: () => this.goToRoute('/admin-actus')
        },
        { title: 'Chiffres',
          icon: 'mdi-counter',
          atClick: () => this.goToRoute('/admin-numbers')
        },
        'divider',
        { title: 'Déconnexion',
          icon: 'mdi-logout',
          tooltip: this.user.email,
          atClick: this.logout,
          color: 'red-darken-3'
        }
      ]
    }
  }
}
</script>