import { createRouter, createWebHistory } from 'vue-router'

const
  needLogin = true

const routes = [
  { name: 'home',
    path: '/',
    component: () => import('@/views/Home.vue')
  },
  { name: 'trainings',
    path: '/trainings',
    component: () => import('@/views/Trainings.vue')
  },
  { name: 'training',
    path: '/training/:id',
    component: () => import('@/views/Training.vue')
  },
  { name: 'aboutUs',
    path: '/aboutUs',
    component: () => import('@/views/AboutUs.vue')
  },
  { name: 'individual',
    path: '/individual',
    component: () => import('@/views/Individual.vue')
  },
  { name: 'company',
    path: '/company',
    component: () => import('@/views/Company.vue')
  },

  // Back Office
  { name: 'login',
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  { name: 'admin-trainings',
    path: '/admin-trainings',
    component: () => import('@/views/admin/AdminTrainings.vue'),
    meta: {
      needLogin
    }
  },
  { name: 'admin-actus',
    path: '/admin-actus',
    component: () => import('@/views/admin/AdminNews.vue'),
    meta: {
      needLogin
    }
  },
  { name: 'admin-numbers',
    path: '/admin-numbers',
    component: () => import('@/views/admin/AdminNumbers.vue'),
    meta: {
      needLogin
    }
  },

  // error
  { name: '404',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/errors/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
