<template>
  <div class="h-100 d-flex align-center justify-center">
    <v-img src="@/assets/images/401.svg" class="bg-img" />
    <div class="bg-filter"></div>
    <v-card elevation=12>
      <v-btn size=x-large color=primary variant=outlined elevation=0 @click="$router.push('/')">Revenir en sécurité</v-btn>
    </v-card>
  </div>
</template>

<script></script>

<style scoped>
.bg-img { opacity: 1; }
.bg-filter {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, .1);
}
</style>